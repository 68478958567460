"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const utils_1 = require("@src/shared/utils");
const ngRightClick_1 = require("@src/app/directives/ngRightClick");
//@ts-ignore
const src_1 = require("monkey-nonogram/src");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const defaultSize = 20;
const sizeInitial = function (value) {
    let numValue = parseInt(value || defaultSize) || defaultSize;
    if ([5, 10, 15, 20, 25, 30, 35, 40].indexOf(numValue) < 0) {
        numValue = defaultSize;
    }
    return numValue;
};
class NonogramsCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService, $q, $location) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.$q = $q;
        this.$location = $location;
        this.size = sizeInitial(this.$location.search()['size']);
        this.data = {};
        this.hoverNonogram = {
            x: undefined,
            y: undefined,
        };
        this.creator = new src_1.Creator();
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem('startWith') || '0');
        this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.gameFinish = false;
        this.highlightCorrect = true;
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!localStorage.getItem(`data`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`data`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
        this.ConfigService.$watchSettings('highlight_correct').subscribe((value) => {
            this.highlightCorrect = value;
            if (this.data.puzzle) {
                if (this.highlightCorrect) {
                    this.data.puzzle.forEach((_, x) => {
                        _.forEach((item, y) => {
                            if (item.state == 'black') {
                                this.doHighlightCorrect(x, y);
                            }
                        });
                    });
                    this.save();
                }
            }
        });
    }
    setSize(size) {
        this.newGame(size);
    }
    newGame(size = undefined) {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem('data');
                localStorage.removeItem('startWith');
                if (size)
                    this.size = size;
                this.makePuzzle(this.size);
                this.setParam('size', this.data.size);
            }
        });
        // if (!localStorage.getItem('data') || force) {
        //     if (size)
        //         this.size = size
        //     this.makePuzzle(this.size)
        //     this.setParam('size', this.data.size)
        // } else {
        //     this._confirmNewGame((result) => {
        //         if (result) {
        //             localStorage.removeItem('data')
        //             this.newGame(true, size)
        //         }
        //     })
        // }
    }
    reset() {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem('data');
                localStorage.removeItem('startWith');
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.data.changed = false;
                this.data.puzzle = ng.copy(this.data.puzzle).map((_, x) => _.map((_, y) => {
                    return { state: undefined, x: x, y: y };
                }));
                this.data.rows = ng.copy(this.data.rows).map((items) => {
                    return items.map((item) => {
                        item.complete = undefined;
                        item.mark = undefined;
                        return item;
                    });
                });
                this.data.columns = ng.copy(this.data.columns).map((items) => {
                    return items.map((item) => {
                        item.complete = undefined;
                        item.mark = undefined;
                        return item;
                    });
                });
            }
        });
    }
    $onInit() {
        const savedData = JSON.parse(localStorage.getItem('data') || 'null');
        if (!savedData) {
            // this.size = defaultSize
            this.setParam('size', this.size);
            this.newGame();
        }
        else {
            this.data = savedData;
            this.size = this.data.size;
            this.setParam('size', this.data.size);
        }
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(0);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    setParam(name, value) {
        console.log(name, value);
        if (name == 'size') {
            if (value == defaultSize) {
                this.$location.search('size', null);
            }
            else {
                this.$location.search('size', value);
            }
        }
    }
    makePuzzle(size) {
        const puzzle = this.creator.createRandom(size == 5 ? 10 : size, size);
        const maxColGroupSize = Math.max(...puzzle.columnHints.map((item) => item.length));
        const maxRowGroupSize = Math.max(...puzzle.rowHints.map((item) => item.length));
        this._startTimer.next(null);
        this.startWith = 0;
        this.timeRemaining = '00:00';
        this.gameFinish = false;
        this.data = {
            size: size,
            columns: puzzle.columnHints.map((col) => {
                return col.map((item) => {
                    return {
                        number: item,
                        state: undefined
                    };
                });
            }),
            maxColGroupSize: maxColGroupSize,
            maxColGroupIndexes: (0, utils_1.arrayRange)(0, maxColGroupSize - 1),
            rows: puzzle.rowHints.map((row) => {
                return row.map((item) => {
                    return {
                        number: item,
                        state: undefined
                    };
                });
            }),
            maxRowGroupSize: maxRowGroupSize,
            maxRowGroupIndexes: (0, utils_1.arrayRange)(0, maxRowGroupSize - 1),
            puzzle: puzzle.rowHints.map((_, x) => puzzle.columnHints.map((_, y) => {
                return { state: undefined, x: x, y: y };
            })),
            answer: puzzle.grid,
        };
    }
    mouseOver(x, y) {
        this.hoverNonogram = {
            x: x,
            y: y,
        };
    }
    puzzleLeftClick($event, x, y) {
        if (!this.gameFinish) {
            this.data.changed = true;
            const cell = this.data.puzzle[x][y];
            if (this.ConfigService.cookieSettings.left_mousedown_cycle) {
                if (cell.state == 'black') {
                    cell.state = 'cross';
                    this.SoundService.play('cross');
                }
                else if (cell.state == 'cross') {
                    cell.state = undefined;
                }
                else if (!cell.state) {
                    cell.state = 'black';
                    this.SoundService.play('black');
                }
            }
            else {
                if (cell.state == 'black') {
                    cell.state = undefined;
                }
                else {
                    cell.state = 'black';
                    this.SoundService.play('black');
                }
            }
            if (this.ConfigService.cookieSettings.highlight_correct) {
                this.doHighlightCorrect(x, y);
            }
            if ((cell.state == 'black') && (this.ConfigService.cookieSettings.autocomplete_cross)) {
                this.doAutocompleteCross(x, y);
            }
            this.save();
            this.checkPuzzle();
        }
    }
    puzzleRightClick($event, x, y) {
        if (!this.gameFinish) {
            this.data.changed = true;
            const cell = this.data.puzzle[x][y];
            cell.state = (cell.state != 'cross') ? 'cross' : undefined;
            if (cell.state == 'cross') {
                this.SoundService.play('cross');
            }
            if (this.ConfigService.cookieSettings.highlight_correct) {
                this.doHighlightCorrect(x, y);
            }
            if ((cell.state == 'black') && (this.ConfigService.cookieSettings.autocomplete_cross)) {
                this.doAutocompleteCross(x, y);
            }
            this.save();
            this.checkPuzzle();
        }
    }
    doMarkCol(col, i) {
        if (!this.highlightCorrect) {
            col[col.length + i - this.data.maxColGroupSize].mark = !col[col.length + i - this.data.maxColGroupSize].mark;
            this.save();
        }
    }
    doMarkRow(row, i) {
        if (!this.highlightCorrect) {
            row[row.length + i - this.data.maxRowGroupSize].mark = !row[row.length + i - this.data.maxRowGroupSize].mark;
            this.save();
        }
    }
    doAutocompleteCross(x, y) {
        const rowSets = [];
        let i = 0;
        this.data.puzzle[x].filter((item) => {
            if (item.state == 'black') {
                i += 1;
            }
            else {
                if (i > 0) {
                    rowSets.push(i);
                }
                i = 0;
            }
        });
        if (i > 0) {
            rowSets.push(i);
        }
        i = 0;
        const colSets = [];
        this.data.puzzle.filter((items) => {
            if (items[y].state == 'black') {
                i += 1;
            }
            else {
                if (i > 0) {
                    colSets.push(i);
                }
                i = 0;
            }
        });
        if (i > 0) {
            colSets.push(i);
        }
        i = 0;
        if (rowSets.length == this.data.rows[x].length) {
            let doCross = this.data.rows[x].every((item, index) => {
                return rowSets[index] == item.number;
            });
            if (doCross) {
                this.data.puzzle[x].forEach((item) => {
                    if (item.state != 'black') {
                        item.state = 'cross';
                    }
                });
            }
        }
        if (colSets.length == this.data.columns[y].length) {
            let doCross = this.data.columns[y].every((item, index) => {
                return colSets[index] == item.number;
            });
            if (doCross) {
                this.data.puzzle.forEach((items) => {
                    if (items[y].state != 'black') {
                        items[y].state = 'cross';
                    }
                });
            }
        }
    }
    doHighlightCorrect(x, y) {
        const rightAnswerRow = [];
        let a = '';
        this.data.answer[x].map((item, y) => {
            if (item) {
                a += `${y},`;
            }
            else {
                if (a)
                    rightAnswerRow.push(a);
                a = '';
            }
        });
        if (a)
            rightAnswerRow.push(a);
        const rightAnswerCol = [];
        let c = '';
        this.data.answer.forEach((cols, x) => {
            const item = cols[y];
            if (item) {
                c += `${x},`;
            }
            else {
                if (c)
                    rightAnswerCol.push(c);
                c = '';
            }
        });
        if (c)
            rightAnswerCol.push(c);
        const rowAnswer = [];
        let b = '';
        this.data.puzzle[x].forEach((item, y) => {
            if (item.state == 'black') {
                b += `${y},`;
            }
            else {
                if (b)
                    rowAnswer.push(b);
                b = '';
            }
        });
        if (b)
            rowAnswer.push(b);
        const colAnswer = [];
        let g = '';
        this.data.puzzle.forEach((rows, x) => {
            const item = rows[y];
            if (item.state == 'black') {
                g += `${x},`;
            }
            else {
                if (g)
                    colAnswer.push(g);
                g = '';
            }
        });
        if (g)
            colAnswer.push(g);
        this.data.rows[x].forEach((item, index) => {
            item.complete = rowAnswer.indexOf(rightAnswerRow[index]) > -1;
        });
        this.data.columns[y].forEach((item, index) => {
            item.complete = colAnswer.indexOf(rightAnswerCol[index]) > -1;
        });
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    endGame() {
        this._stopTimer.next(null);
        this.gameFinish = true;
        this.SoundService.play('win');
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                timeRemaining: this.timeRemaining,
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            localStorage.removeItem('data');
            localStorage.removeItem('startWith');
            if (result == 'newGame') {
                this.newGame();
            }
        });
    }
    checkPuzzle() {
        if (this.data.puzzle) {
            let gameFinish = true;
            this.data.puzzle.forEach((row, x) => {
                row.forEach((cell, y) => {
                    if (this.data.answer[x][y] == 1) {
                        if (cell.state != 'black') {
                            gameFinish = false;
                        }
                    }
                    else {
                        if (cell.state == 'black') {
                            gameFinish = false;
                        }
                    }
                });
            });
            if (gameFinish) {
                this.data.puzzle.forEach((row, x) => {
                    row.forEach((cell, y) => {
                        if (cell.state != 'black') {
                            cell.state = 'cross';
                        }
                    });
                });
            }
            if (gameFinish) {
                this.endGame();
            }
            return gameFinish;
        }
        return false;
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem('startWith', sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    save() {
        this._startTimer.next(true);
        localStorage.setItem('data', JSON.stringify(ng.copy(this.data)));
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !localStorage.getItem('data')) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            const timerWork = this.timerWork;
            this._stopTimer.next(null);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    if (timerWork) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
}
NonogramsCtrl.$inject = [
    '$scope',
    '$timeout',
    'ModalServiceFactory',
    'ConfigService',
    'SoundService',
    '$q',
    '$location',
];
const appModule = ng.module('app');
appModule.directive('ngRightClick', ngRightClick_1.NgRightClick);
appModule.component('gameNonograms', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: NonogramsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<",
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('nonograms/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'cross': require('./sounds/cross.mp3').default,
            'black': require('./sounds/black.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
            highlight_correct: true,
            autocomplete_cross: true,
            left_mousedown_cycle: true,
        });
    }]);
